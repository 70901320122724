<template>
  <div id="app">
    <div class="navbar navbar-inverse" style="">
      <div class="navbar-inner" style="">
        <div class="container" style="width: auto">
          <a
            class="btn btn-navbar"
            data-toggle="collapse"
            data-target=".nav-collapse"
          >
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </a>
          <router-link class="brand" to="/">The Obesity Epidemic</router-link>
          <div class="nav-collapse">
            <ul class="nav">
              <li><router-link to="/sources/0">Source #1</router-link></li>
              <li><router-link to="/sources/1">Source #2</router-link></li>
              <li><router-link to="/sources/2">Source #3</router-link></li>
              <li><router-link to="/conclusion">Conclusion</router-link></li>
            </ul>
          </div>
          <!-- /.nav-collapse -->
        </div>
      </div>
      <!-- /navbar-inner -->
    </div>
    <router-view />
  </div>
</template>

<style></style>

<script>
export default {
  name: "name",
  mounted() {},
};
</script>
